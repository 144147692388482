<template>
    <div>
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Documents
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="newDocument" color="primary" style="text-transform:none">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    Here you can share important documents with all your residents
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterDocuments" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="documents" :search="filterDocuments" :items-per-page="25">
                        <template v-slot:item.name="{ item }">
                            <div>{{ item.name }}</div>
                            <div class="caption grey--text text--darken-1">{{ item.description }}</div>
                        </template>
                        <template v-slot:item.mimeType="{ item }">
                            <img :src="require(`../assets/images/icons/${item.extension}.svg`)" width="32" />
                        </template>
                        <template v-slot:item.isPublished="{ item }">
                            {{ item.isPublished ? 'Yes' : 'No' }}
                        </template>
                        <template v-slot:item.dateCreated="{ item }">
                            {{ formatDate( item.dateCreated, 'YYYY-MM-DD @ HH:mm a' ) }}
                        </template>
                        <template v-slot:item.more="{ item }">
                            <v-btn icon @click="downloadFile(item)">
                                <v-icon>mdi-download-outline</v-icon>
                            </v-btn>
                            <v-btn icon @click="editDocument(item)">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                            <v-btn icon @click="showWarning(item)">
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

        <v-dialog v-model="warningDialog" width="400">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    <strong>Are you sure you want to delete this file?</strong> This cannot be undone and your residents won't be able to download it anymore.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="warningDialog = false" style="text-transform: none;">Cancel</v-btn>
                    <v-btn depressed color="error" @click="deleteDocument" style="text-transform: none;">Yes, I'm sure</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <document ref="document" :dialog="true" :dialogTitle="documentDialogTitle" :dialogSubtitle="documentDialogSubtitle" :dialogOpen="documentDialog" 
            @cancel="documentDialog = false" @saved="addDocument2Table" @update="updateDocument"></document>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Document from '@/components/Document.vue';

export default {
    data: () => ({
        loaded          : false,
        deleting        : false,
        headers         : [
            { text: '', value: 'mimeType', sortable: false, width: '5%' },
            { text: 'Name', value: 'name', sortable: true },
            { text: 'Size', value: 'size', sortable: true },
            { text: 'Published', value: 'isPublished', sortable: true },
            { text: 'Created At', value: 'dateCreated', sortable: true },
            { text: 'Actions', value: 'more', sortable: false, align: 'right' },
        ],
        filterDocuments : '',

        docToDelete: null,
        warningDialog: false,

        documentDialog: false,
        documentDialogTitle: 'New Document',
        documentDialogSubtitle: 'Add new document to your community',
    }),
    computed: {
        ...mapGetters({
            documents       : 'documents/documents',
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadDocuments   : 'documents/load',
            deleteDoc       : 'documents/deleteDocument',
        }),
        async init() {
            try {
                if( this.documents.length == 0 || this.needRefresh ) {
                    await this.loadDocuments();
                }
            }
            catch(error) {
                this.mxShowMessage( error, 'error' );
            }
            finally {
                this.loaded = true;
            }
        },
        addDocument2Table(doc) {
            let ix = this.documents.findIndex(r => {
                return r.id == doc.id;
            });

            if( ix > -1 ) {
                this.documents[ix] = doc;
                return; // is an update
            }

            this.documents.push(doc);
        },
        updateDocument(doc) {
            let found = this.documents.find(r => {
                return r.id == doc.id;
            });
            
            if( found == undefined || found == null ) 
                return; // is not an update

            found.name            = doc.name;
            found.description     = doc.description;
            found.isPublished     = doc.isPublished;
        },
        newDocument() {
            this.documentDialogTitle = 'New Document';
            this.documentDialogSubtitle = 'Add new document to your community';

            this.documentDialog = true;
        },
        editDocument(doc) {
            this.documentDialogTitle = 'Edit Document';
            this.documentDialogSubtitle = 'Edit the selected document information';

            this.$refs.document.load( doc );
            this.documentDialog = true;
        },
        downloadFile( doc ) {
            let link = document.createElement("a");
            link.download = `${doc.name}.${doc.extension}`;
            link.href = doc.src;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // delete link;
        },
        showWarning( doc ) {
            this.docToDelete = doc;
            this.warningDialog = true;
        },
        async deleteDocument() {
            try {
                this.deleting = true;
                this.deleteDoc( this.docToDelete.id );

                // remove it from table
                let ix = this.documents.findIndex(r => {
                    return r.id == this.docToDelete.id;
                });

                if( ix > -1 ) {
                    this.documents.splice( ix, 1 );
                }

                this.docToDelete = null;
                this.warningDialog = false;
            }
            catch(error) {
                this.mxShowMessage(error.message, error.error ? 'error' : 'warning');
            }
            finally {
                this.deleting = false;
            }
        }
    },
    components: {
        Document
    }
}
</script>

<style lang="scss" scoped>

</style>