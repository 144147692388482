var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticStyle:{"height":"100%"},attrs:{"type":"list-item-three-line","loading":!_vm.loaded,"transition":"fade-transition"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Documents "),_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.newDocument}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add new ")],1)],1),_c('v-card-subtitle',[_vm._v(" Here you can share important documents with all your residents ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"6","offset-lg":"6","offset-xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.filterDocuments),callback:function ($$v) {_vm.filterDocuments=$$v},expression:"filterDocuments"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documents,"search":_vm.filterDocuments,"items-per-page":25},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"caption grey--text text--darken-1"},[_vm._v(_vm._s(item.description))])]}},{key:"item.mimeType",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":require(("../assets/images/icons/" + (item.extension) + ".svg")),"width":"32"}})]}},{key:"item.isPublished",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPublished ? 'Yes' : 'No')+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( item.dateCreated, 'YYYY-MM-DD @ HH:mm a' ))+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',[_vm._v("mdi-download-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editDocument(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showWarning(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.warningDialog),callback:function ($$v) {_vm.warningDialog=$$v},expression:"warningDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Warning")]),_c('v-card-text',[_c('strong',[_vm._v("Are you sure you want to delete this file?")]),_vm._v(" This cannot be undone and your residents won't be able to download it anymore. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":""},on:{"click":function($event){_vm.warningDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","color":"error"},on:{"click":_vm.deleteDocument}},[_vm._v("Yes, I'm sure")])],1)],1)],1),_c('document',{ref:"document",attrs:{"dialog":true,"dialogTitle":_vm.documentDialogTitle,"dialogSubtitle":_vm.documentDialogSubtitle,"dialogOpen":_vm.documentDialog},on:{"cancel":function($event){_vm.documentDialog = false},"saved":_vm.addDocument2Table,"update":_vm.updateDocument}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }