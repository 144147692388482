<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="800" persistent>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="local.name" label="First Name" :error-messages="nameError"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="local.description" label="Description"></v-textarea>
                            </v-col>
                            <v-col cols="12" v-if="local.id == 0">
                                <v-file-input v-model="local.src" label="File" :error-messages="fileError" 
                                    :accept="acceptedFiles"></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-switch v-model="local.isPublished" label="Published"></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text class="ma-0 pa-0">
                <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="local.name" label="First Name" :error-messages="nameError"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea v-model="local.description" label="Description"></v-textarea>
                            </v-col>
                            <v-col cols="12" v-if="local.id == 0">
                                <v-file-input v-model="local.src" label="File" :error-messages="fileError"
                                    :accept="acceptedFiles"></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-switch v-model="local.isPublished" label="Published"></v-switch>
                            </v-col>
                        </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'New Document'
        },
        dialogSubtitle: {
            type: String,
            default: 'Add new document to your community'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        acceptedFiles: [
            'application/pdf', 
            'text/plain', 
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        nameError: '',
        fileError: '',
        local: {
            id: 0,
            name: '',
            description: '',
            mimeType: '',
            extension: '',
            src: null,
            size: '',
            isPublished: false,
            dateCreated: null
        },
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',
    }),
    computed: {
        ...mapGetters({
            locationId  : 'auth/locationId',
        }),
    },
    methods: {
        ...mapActions({
            saveDoc     : 'documents/saveDocument'
        }),
        load( doc ) {
            this.local = {
                id              : doc.id,
                name            : doc.name,
                description     : doc.description,
                mimeType        : doc.mimeType,
                extension       : doc.extension,
                src             : doc.src,
                size            : doc.size,
                isPublished     : doc.isPublished,
                dateCreated     : doc.dateCreated
            }
        },
        cancel() {
            this.$emit('cancel');
            this.local = {
                id              : 0,
                name            : '',
                description     : '',
                mimeType        : '',
                extension       : '',
                src             : null,
                size            : '',
                isPublished     : false,
                dateCreated     : null
            }
            this.nameError = this.fileError = '';
            this.showAlert = false;
        },
        isValid() {
            let errorFound = false;
            this.nameError = this.fileError = '';

            if( this.local.name.length == 0 ) {
                this.nameError = 'required';
                errorFound = true;
            }
            if( this.local.id == 0 && this.local.src.length == 0 ) {
                this.fileError = 'required';
                errorFound = true;
            }

            return !errorFound;
        },
        async save() {
            if( !this.isValid() )
                return;

            try {
                this.saving = true;

                let doc = await this.saveDoc( this.local );
                
                // is an update
                if( this.local.id > 0 ) {
                    this.$emit('update', doc);
                    this.cancel();
                    return;
                }

                this.$emit('saved', doc);
                this.local.id = doc.id;

                // show confirmation message
                this.alertMessage = 'Document saved successfully';
                this.alertColor = 'success';
                this.showAlert = true;
                
                this.cancel();
            }
            catch(error) {
                this.alertMessage = error.message;
                this.alertColor = error.error ? 'error' : 'warning';
                this.showAlert = true;
                console.log(error);
            }
            finally {
                this.saving = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>